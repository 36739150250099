import React from "react";
import "normalize.css";
import "./Constants.css"

import Home from "./components/Home/Home";
import News from "./components/News/News";
import Contact from "./components/Contact/Contact";
import Services from "./components/Services/Services";
import ServicePage from "./components/Services/ServicePage/ServicePage";
import CoursePage from "./components/Courses/CoursePage/CoursePage";
import PrivacyPolicy from "./components/Legal/Privacy-Policy/Privacy-Policy";
import CookiePolicy from "./components/Legal/Cookie-Policy/Cookie-Policy";
import Mission from "./components/About/Mission/Mission";
import OurTeam from "./components/About/Our-Team/Our-Team";


import { Routes, BrowserRouter, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Courses from "./components/Courses/Courses";



function App() {
  return (
    <main>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="news" element={<News />} />
        <Route path="contact" element={<Contact />} />
        <Route path="services" element={<Services />} />
        <Route path="service/:title" element={<ServicePage />} />
        <Route path="course/:title" element={<CoursePage />} />
        <Route path="courses" element={<Courses />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="cookie-policy" element={<CookiePolicy />} />
        <Route path="mission" element={<Mission />} />
        <Route path="our-team" element={<OurTeam />} />
      </Routes>
    </main>
  );
}

export default App;
