import React from "react";
import { useParams } from "react-router-dom";
import { generateSlug } from "../../../utilities/helpers";
import { ServiceData } from "../ServiceData";
import { Link } from "react-router-dom";

import Navbar from "../../Navbar/Navbar";
import MainBodyContainer from "../../Common/Body/MainBodyContainer/MainBodyContainer";
import Footer from "../../Footer/Footer";

import "../ServicePage/ServicePage.css";

function ServicePage() {
  const { title } = useParams();

  // Find the service data based on the 'title' slug
  const service = ServiceData.find(
    (service) => generateSlug(service.title) === generateSlug(title)
  );

  if (!service) {
    // Handle the case where the service with the given 'title' is not found
    return (
      <div>
        <h1>Service Not Found</h1>
      </div>
    );
  }

  // Check if imageURL exists in the service data
  const hasImage = !!service.imageURL;

  return (
    <div className="service-page-container">
      <Navbar />
      <MainBodyContainer heading={service.title}>
        <div className="service-content">
          <div className="left-content">
            <h1 className="service-description">{service.description}</h1>
            {service.content.map((section, index) => (
              <div key={index}>
                {section.heading && (
                  <h2 className="section-heading">{section.heading}</h2>
                )}
                {section.text &&
                  section.text.map((paragraph, i) => (
                    <p className="section-text" key={i}>
                      {paragraph}
                    </p>
                  ))}
                {hasImage && section.list && (
                  <div className="image-ul-container">
                    <ul className="section-bulletpoints">
                      {section.list.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                    <img
                      src={service.imageURL}
                      alt={service.title}
                      className="service-image"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <Link to="/contact" className="body-button">
          <div className="body-btn-circle"></div>
          <a href="/">Contact Us</a>
        </Link>
      </MainBodyContainer>
      <Footer />
    </div>
  );
}

export default ServicePage;
