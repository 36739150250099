export const NavbarItems = [
  {
    title: "Home",
    url: "/",
    className: "nav-links",
    subnavitems: [],
  },

  {
    title: "About",
    url: "/mission",
    className: "nav-links",
    subnavitems: [
      {
        category: "",
        categoryDescription: "",
        items: [
          {
            title: "mission",
            url: "/mission",
            className: "subnav-links",
          },
          {
            title: "team",
            url: "/our-team",
            className: "subnav-links",
          },
          // {
          //   title: "careers",
          //   url: "#",
          //   className: "subnav-links",
          // },
        ],
      },
    ],
  },

  {
    title: "Services",
    url: "/services",
    className: "nav-links",
    subnavitems: [
      {
        category: "Demographic Programmes",
        categoryDescription:
          "Our employment programmes are engineered to provide you the best chances at employment.",
        items: [
          {
            title: "careers advice",
            description:
              "Receive expert advice on planning and advancing your career.",
            url: "/service/careers-advice",
            className: "subnav-links",
          },
          {
            title: "employability skills",
            description:
              "Receive expert guidance and support towards employability",
            url: "/service/employability-skills",
            className: "subnav-links",
          },
          {
            title: "vocational training",
            description:
              "",
            url: "/service/vocational-training",
            className: "subnav-links",
          },
          // {
          //   title: "youth empowerment program",
          //   description:
          //     "Empower the youth with essential skills and knowledge for a successful future.",
          //   url: "/service/youth-empowerment-program",
          //   className: "subnav-links",
          // },
          // {
          //   title: "women's project",
          //   description:
          //     "Supporting and empowering women through various skill-building projects.",
          //   url: "/service/womens-project",
          //   className: "subnav-links",
          // },
        ],
      },
      // {
      //   category: "Online Services",
      //   categoryDescription:
      //     "Our services are designed to help you enhance your employability.",
      //   items: [
      //     {
      //       title: "create a CV",
      //       description:
      //         "Build a compelling CV that highlights your skills and experiences.",
      //       url: "#",
      //       className: "subnav-links new-feature",
      //     },
      //     {
      //       title: "learning portal",
      //       description:
      //         "Access our online learning portal for skill development and education.",
      //       url: "#",
      //       className: "subnav-links new-feature",
      //     },
      //   ],
      // },
    ],
  },

  {
    title: "Courses",
    url: "/courses",
    className: "nav-links",
    subnavitems: [
      {
        category: "",
        categoryDescription: "Accredited Courses Delivered by APT",
        items: [
          {
            title: "Advice & Guidance Level 1 - 3",
            description: "",
            url: "/course/advice-guidance-level-1-3",
            className: "subnav-links",
          },
          {
            title: "Functional Skills (English & Maths)",
            description: "",
            url: "/course/functional-skills-english-maths",
            className: "subnav-links",
          },
          {
            title: "Business Administration Level 1 - 2",
            description: "",
            url: "/course/business-administration-level-1-2",
            className: "subnav-links",
          },
          {
            title: "Customer Service Level 1 - 3",
            description: "",
            url: "/course/customer-service-level-1-3",
            className: "subnav-links",
          },
          {
            title: "Health & Social Care Level 1 - 3",
            description: "",
            url: "/course/health-social-care-level-1-3",
            className: "subnav-links",
          },
          {
            title: "Health & Safety in the Workplace Level 1 - 3",
            description: "",
            url: "/course/health-safety-in-the-workplace-level-1-3",
            className: "subnav-links",
          },
          {
            title: "Mental Health Awareness & Wellbeing Level 1 - 2",
            description: "",
            url: "/course/mental-health-awareness-wellbeing-level-1-2",
            className: "subnav-links",
          },
          {
            title: "Safeguarding & Protecting Children & Young people Level 2",
            description: "",
            url: "/course/safeguarding-protecting-children-young-people-level-2",
            className: "subnav-links",
          },
          {
            title: "Equality & Diversity Level 2",
            description: "",
            url: "/course/equality-diversity-level-2",
            className: "subnav-links",
          },
          {
            title: "Food & Safety Level 1 - 3",
            description: "",
            url: "/course/food-safety-level-1-3",
            className: "subnav-links",
          },
          {
            title: "Paediatric First Aid",
            description: "",
            url: "/course/paediatric-first-aid",
            className: "subnav-links",
          },
          {
            title: "British Values Entry Level 3",
            description: "",
            url: "/course/british-values-entry-level-3",
            className: "subnav-links",
          },
          {
            title: "Emergency First Aid in the Workplace (EFAW)",
            description: "",
            url: "/course/emergency-first-aid-in-the-workplace",
            className: "subnav-links",
          },
        ],
      },
    ],
  },

  {
    title: "News",
    url: "/news",
    className: "nav-links",
    subnavitems: [],
  },

  {
    title: "Contact",
    url: "/contact",
    className: "nav-links",
    subnavitems: [],
  },
];