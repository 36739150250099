export const ServiceData = [
  {
    title: "careers advice",
    description: "Receive expert advice on planning and advancing your career.",
    imageURL:
      "https://images.unsplash.com/photo-1527689368864-3a821dbccc34?auto=format&fit=crop&q=80&w=3270&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "Welcome to our dynamic careers advice hub, where personalized support from our dedicated staff sets us apart. Our team is committed to guiding you through the intricate landscape of professional growth, ensuring that you not only discover your passions but also seamlessly connect them to enriching career opportunities. From crafting compelling resumes to mastering interview techniques, our staff is here to provide hands-on assistance. Dive into our interactive tools, engage in illuminating webinars, and draw inspiration from success stories that resonate with your unique journey. Whether you're a recent graduate, a seasoned professional, or somewhere in between, our mission is to equip you with the insights and confidence needed to excel in today's ever-changing job market. Your dream career is within reach, and our staff is here to champion your success every step of the way!",
        ],
      },
      {
        heading: "Unlocking Your Path to Success",
        text: [
          "Are you contemplating a career change or planning to rejoin the workforce after a career break? Our career advisers are here to help you explore realistic and achievable goals. Our staff are experts in careers advising and determined to keep you motivated and overcome barriers toward a successful career.",
        ],
      },
      {
        heading: "We Can Help You With:",
        list: [
          "Confidence boosting",
          "Career planning",
          "Develop a tailored CV and Cover letter",
          "Finding and applying for job opportunities",
          "Job interview skills",
          "Advice on self-employment and setting up your own enterprise",
          "Finding the right course or training for you",
          "Applying for university courses",
          "Employer negotiation",
          "Other employability related skills",
        ],
      },
      {
        heading: "Your Path to Informed Choices",
        text: [
          "If you need impartial information, advice, and guidance to help you make an informed choice in learning, training, or career, don't hesitate to reach out to our dedicated careers team. We're here to support you every step of the way, ensuring you make the right choices that lead to your success.",
        ],
      },
    ],
  },
  {
    title: "Employability Skills",
    description: "",
    imageURL:
      "https://images.unsplash.com/photo-1527689368864-3a821dbccc34?auto=format&fit=crop&q=80&w=3270&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "Discover the pinnacle of vocational education through our expertise in providing a diverse range of vocationally related courses, both credited and non-credited. As an organization accredited by the prestigious Highfield Awarding Body and regulated by Ofqual, we pride ourselves on delivering courses of the highest quality. Our team comprises fully qualified trainers, assessors, and verifiers who offer accredited qualifications and bespoke training tailored for the needs of both students and businesses.",
          "Our experience in the field ensures that our courses not only meet industry standards but exceed expectations. Businesses, in particular, stand to benefit significantly from our programs, which contribute to staff retention, customer satisfaction, and overall business stability. Explore our courses to unlock opportunities for personal and professional growth. For a detailed list of our most popular courses, visit the Courses tab and embark on a journey towards excellence with us",
        ],
      },
    ],
  },
  {
    title: "Vocational Training",
    description: "",
    imageURL:
      "https://images.unsplash.com/photo-1527689368864-3a821dbccc34?auto=format&fit=crop&q=80&w=3270&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: [
      {
        heading: "",
        text: [
          "Discover the pinnacle of vocational training through our expertise in providing a diverse range of vocationally related courses, both credited and non-credited. As an organization accredited by the prestigious Highfield Awarding Body and regulated by Ofqual, we pride ourselves on delivering courses of the highest quality. Our team comprises fully qualified trainers, assessors, and verifiers who offer accredited qualifications and bespoke training tailored for the needs of both students and businesses.",
          "Our experience in the field ensures that our courses not only meet industry standards but exceed expectations. Businesses, in particular, stand to benefit significantly from our qualifications, which contribute to staff retention, customer satisfaction, and overall business stability. Explore our courses to unlock opportunities for personal and professional growth. For a detailed list of our most popular courses, visit the 'Courses' tab and embark on a journey towards excellence with us.",
          "To find out more please contact us.",
        ],
      },
    ],
  },
];
