export const Partners = [
  {
    name: "Highfields",
    logoURL: "highfields-logo.png",
    externalURL: "https://www.highfieldqualifications.com/",
  },
  {
    name: "Disability Confident",
    logoURL: "disability-confident-logo.png",
    externalURL: "https://disabilityconfident.campaign.gov.uk/",
  },
  {
    name: "Shpresa Programme",
    logoURL: "shpresa-programme-logo.png",
    externalURL: "https://shpresaprogramme.org/",
  },
];