import React, { Component } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import ReactTooltip from "react-tooltip";
import "animate.css";

import Navbar from "../../Navbar/Navbar";
import MainBodyContainer from "../../Common/Body/MainBodyContainer/MainBodyContainer";
import Footer from "../../Footer/Footer";

import "../Mission/Mission.css";

class Mission extends Component {
  companyFigures = [
    // {
    //   figure: "1000",
    //   description: "Students Taught",
    // },
    // {
    //   figure: "20",
    //   description: "Courses Delivered",
    // },
    // {
    //   figure: "100",
    //   description: "Something Something",
    // },
    // {
    //   figure: "100",
    //   description: "Something Something",
    // },
    // {
    //   figure: "100",
    //   description: "Something Something",
    // },
    // {
    //   figure: "100",
    //   description: "Something Something",
    // },
    // {
    //   figure: "100",
    //   description: "Something Something",
    // },
    // {
    //   figure: "100",
    //   description: "Something Something",
    // },
  ];

  companyValues = [
    {
      phrase: "Empower",
      description: "Empowering employability through effective training",
    },
    {
      phrase: "Innovate",
      description:
        "Understanding our customers to deliver services that meet their needs",
    },
    {
      phrase: "Opportunity",
      description:
        "Provide equal opportunities to participant from different backgrounds",
    },
    {
      phrase: "Respect",
      description:
        "Respect members of all backgrounds and cultures, promoting an inclusive and belonging environment",
    },
  ];

  render() {
    return (
      <div>
        <Navbar />
        <div>
          <MainBodyContainer heading={"Our Mission"}>
            <div className="two-column-container">
              <div className="left-column">
                <h1 className="animate__animated animate__fadeInUp">
                  WE GIVE YOU THE
                </h1>
                <h1 className="animate__animated animate__fadeInUp stylised-text">
                  SKILLS & CONFIDENCE
                </h1>
                <h1 className="animate__animated animate__fadeInUp">
                  FOR THE FUTURE YOU <span className="stylised-text">WANT</span>
                </h1>
              </div>
              <div className="right-column">
                <div>
                  <img src="https://images.unsplash.com/photo-1538688423619-a81d3f23454b?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"></img>
                </div>
              </div>
            </div>
            <div className="body-section-container">
              <div className="body-row">
                <div className="body-column">
                  <h2 className="section-header">Our Values</h2>
                </div>
                <div className="body-column">
                  <div className="column-text">
                    <p>
                      <span>
                        Our mission is to improve access to high quality
                        education and employment, through training and
                        enterprise. Our target audience are the community
                        members lacking essential skills to break employment
                        barriers and professionally compete into the job market.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="values-container">
                {this.companyValues.map((value) => {
                  return (
                    <div data-tip={value.description} className="values-item">
                      <h2>{value.phrase.substring(0, 1)}</h2>
                      <p>{value.phrase}</p>
                      <ReactTooltip
                        className="tooltip"
                        place="bottom"
                        type="light"
                        effect="solid"
                      />
                    </div>
                  );
                })}
              </div> */}
            </div>
            {/* <div className="">
              <div className="apt-numbers">
                <div className="stats-title stats-title-top">
                  <h2>The Numbers Game</h2>
                </div>
                <div className="body-row stats-row">
                  {this.companyFigures.map((entry) => {
                    return (
                      <div className="stats-item">
                        <h2 className="stats-number">
                          <VisibilitySensor
                            className="tooltip"
                            partialVisibility
                            offset={{ bottom: 20 }}
                          >
                            {({ isVisible }) => (
                              <div style={{ height: 70 }}>
                                {isVisible ? (
                                  <CountUp end={entry.figure} />
                                ) : null}
                              </div>
                            )}
                          </VisibilitySensor>
                        </h2>
                        <h6 className="stats-description">
                          {entry.description}
                        </h6>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}
          </MainBodyContainer>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Mission;