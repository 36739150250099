import React, { Component } from "react";
import "./Testimonials.css";

const testimonialQuotes = [
  {
    content:
      "Enrolling in the courses provided by this training company was a game-changer for me. The comprehensive curriculum, combined with one-on-one support, transformed my skill set and boosted my confidence. I'm now equipped with the tools needed to succeed in my career.",
    author: "Emily S.",
  },
  {
    content:
      "The support I received here was unparalleled. From resume building to interview preparation, every step of the way, I felt guided and encouraged. The work placements provided invaluable hands-on experience, making my transition to the workforce seamless.",
    author: "Alex M., Alumni",
  },
  {
    content:
      "I can't thank this training company enough for helping me secure my dream job. The personalized attention and practical training made a significant difference in my job search. The courses are not just about learning; they are about preparing you for success.",
    author: "Daniel L.",
  },
];

class Testimonials extends Component {
    constructor() {
        super();
        this.state = { 
            quoteId: 0,
            fade: "fade-in"
        };
    }

    componentDidMount() {

        this.Interval = setInterval(() => {
            let fadeStatus = this.state.fade;
            if (fadeStatus === "fade-in") {
              this.setState({ fade: "fade-out" });
              
              this.Timeout = setTimeout(() => {
                this.setState({ fade: "fade-in" });

                let selectedQuote = this.state.quoteId;
                this.setState({ quoteId: selectedQuote + 1 });

              }, 1000)
            }
        }, 6000)
    }

    render() {
        let testimonialQuote = testimonialQuotes[this.state.quoteId % testimonialQuotes.length];

        return (
          <div className="testimonial-quote-container">
            <p className={"testimonial-content " + this.state.fade}>
              &ldquo;
              <br />
              <span>{testimonialQuote.content}.</span>
              <br />
              <br />
              &rdquo;
            </p>
            <p className={"testimonial-author " + this.state.fade}>
              —{testimonialQuote.author}
            </p>
          </div>
        );
    }
}

export default Testimonials;