import React, { Component } from "react";
import { Link } from "react-router-dom";
import { NavbarItems } from "../../Navbar/NavbarItems";
import Testimonials from "../Testimonials/Testimonials"
import "./Overview.css";

class Overview extends Component {
    render() {
        return (
          <div className="overview-container">
            <div className="overview-header">
              <h1>Alisar Prospects Training</h1>
              <h3>
                Building blocks to your success{" "}
                <img src="https://img.icons8.com/ios-glyphs/80/000000/stairs-up.png" />
              </h3>
            </div>
            <div className="overview-content">
              <br />
              <h2>
                We're on a mission to make sure our community members have an
                equal chance,
                <span> regardless of background.</span>
              </h2>
              <p className="subheading-text">
                Explore a range of courses, elevate your CV, master job search
                techniques, refine your interview skills, secure valuable work
                placements, and receive personalized one-to-one support. Our
                mission is to ensure everyone has an equal chance to succeed,
                irrespective of their background.
                <br />
                <br />
                Join us on a transformative journey to unleash your potential
                and become the best version of yourself. We are committed to
                providing the necessary tools and support to help you thrive in
                your career.
              </p>
              <br /> <br /> <br />
              <h3>Your Path to Success Begins Here</h3>
              <p>
                Embark on a journey of personal and professional growth.
                Discover new possibilities and opportunities as you build a
                foundation for a successful career.
              </p>
              <br /> <br /> <br />
              <h3>Reach Out for Support</h3>
              <p>
                Let us know how we can tailor our support to meet your training
                and employment needs. We are here for you every step of the way.
              </p>
              <Link to="/contact" className="body-button">
                <div className="body-btn-circle"></div>
                <a href="/">Contact Us</a>
              </Link>
            </div>
            <div className="overview-subcontent">
              <div>
                <h3 className="subcontent-header">
                  <span>Delivered By Alisar Prospects Training</span>
                </h3>
                <ul className="subcontent-list">
                  <li>
                    <a href="/">Employment Support</a>
                  </li>
                  <li>
                    <a href="/">Careers Advice</a>
                  </li>
                  <li>
                    <a href="/">ESOL Courses</a>
                  </li>
                  <li>
                    <a href="/">Women's support</a>
                  </li>
                  <li>
                    <a href="/">Immigrants Support</a>
                  </li>
                  <li>
                    <a href="/">Other Offerings</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="subcontent-header testimonial-header">
                  <span>Testimonials</span>
                </h3>
                <div className="testimonial-container">
                  <Testimonials />
                </div>
              </div>
            </div>
          </div>
        );
    }
}




export default Overview;