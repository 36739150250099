import React, { Component } from "react";
//import Popup from "reactjs-popup";
import Popup from "../../Common/Popup/Popup";

import MainBodyContainer from "../../Common/Body/MainBodyContainer/MainBodyContainer";
import Footer from "../../Footer/Footer";
import HamburgerMenu from "../../Navbar/HamburgerMenu/HamburgerMenu";
import Navbar from "../../Navbar/Navbar";

import "../Our-Team/Our-Team.css"


class OurTeam extends Component {
  employeeList = [
    {
      name: "Emi",
      title: "Assessor & Verifier",
      description: "",
      photo: "emigjoni.jpeg",
    },
    {
      name: "Arlidio",
      title: "IT & Business Development",
      description: "",
      photo: "empty.png",
    },
    {
      name: "Julia",
      title: "Vocational Trainer",
      description: "",
      photo: "empty.png",
    },
    {
      name: "Lina",
      title: "Admin & Project Coordinator",
      description: "",
      photo: "empty.png",
    },
  ];

  render() {
    return (
      <div>
        <Navbar />
        <div>
          <MainBodyContainer heading={"Our Team"}>
            <div className="subheading-description">
              <h3>
                At Alisar, our team consists of devoted professionals driven to
                instigate positive change within our community. We're equipped
                with qualified and experienced careers advisers, trainers,
                assessors, and Internal Verifiers. We warmly embrace partners
                who resonate with our vision supporting and creating
                opportunities for women and young poeple as well as other
                marginalised groups, notably within BME communities facing
                evident disadvantages.
                <br />
                <br />
                At APT, we strongly advocate equality, diversity celebration,
                and the empowerment of women. Our workplace ethos prioritizes a
                non-judgmental, adaptable environment, fostering a well-balanced
                approach. Our primary focus lies in igniting fervor and
                commitment within our staff to motivate and assist others in
                achieving their aspirations. Our primary objective is to serve
                as a role model, actively championing and empowering a diverse
                community, fostering collaboration among all members, regardless
                of their backgrounds.
              </h3>
            </div>
            <div>
              <div className="row">
                {this.employeeList.map((employee) => {
                  return <Popup employee={employee}></Popup>;
                })}
              </div>
            </div>
          </MainBodyContainer>
        </div>
        <Footer />
      </div>
    );
  }
}

export default OurTeam;